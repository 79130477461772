import { Global } from '@emotion/react';
import type { FC } from 'react';

export const Orders: FC = () => (
  <Global
    styles={{
      '.scenes-container': { zIndex: 1 },
      '.logo-container': { zIndex: 2 },
      '.subtitles-container': { zIndex: 3 },
      '.transitions-container': { zIndex: 4 },
    }}
  />
);
