import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Card, LoadingSpinner, PageHeadline, useGoto } from '@cofenster/web-components';

import { useCofensterProjectTemplate } from '../../../api/hooks/projectTemplate/useCofensterProjectTemplate';
import { AdminLayout } from '../../../components/layout/AdminLayout';
import { PermissionRestriction } from '../../../contexts/staffUser/PermissionRestriction';
import { useImpersonateProject } from '../../../hooks/useImpersonateProject';
import { type ProjectTemplateRouteParams, routes } from '../../../routes';
import { ErrorContent, ErrorLayout } from '../../Error';
import { NoAccessLayout } from '../../NoAccess';
import { ProjectTemplateForm } from './ProjectTemplateForm';

export const ProjectTemplate: FC = () => {
  const goto = useGoto();
  const { projectTemplateId } = useParams() as ProjectTemplateRouteParams;
  const { cofensterProjectTemplate, loading, error } = useCofensterProjectTemplate(projectTemplateId);
  const project = cofensterProjectTemplate?.project;
  const title = project?.name ?? 'Project Template';
  const gotoProjectTemplateEdit = useImpersonateProject(project?.id, `/project/${project?.id}`);

  if (error) {
    return <ErrorLayout title={title} />;
  }

  return (
    <PermissionRestriction has="ProjectTemplateUpdate" fallback={<NoAccessLayout />}>
      <AdminLayout
        documentTitle={title}
        header={{
          topLeft: <PageHeadline mb={0} title={title} onBack={() => goto(routes.projectTemplates)} />,
          topRight: (
            <PermissionRestriction has="UserImpersonate">
              <Button onClick={gotoProjectTemplateEdit}>Edit</Button>
            </PermissionRestriction>
          ),
        }}
      >
        <Card>
          {cofensterProjectTemplate ? (
            <ProjectTemplateForm projectTemplate={cofensterProjectTemplate} />
          ) : loading ? (
            <LoadingSpinner />
          ) : (
            <ErrorContent />
          )}
        </Card>
      </AdminLayout>
    </PermissionRestriction>
  );
};
