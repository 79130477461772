export type AccountRouteParams = { accountId: string; userId?: string };
export type TemplateRouteParams = { renderTemplateId: string };
export type InspectRouteParams = { id: string };
export type MusicRouteParams = { musicId: string };
export type ProjectRouteParams = { projectId: string };
export type ProjectTemplateRouteParams = { projectTemplateId: string };
export type StaffUserRouteParams = { staffUserId: string };
export type TranscriptionReviewAssetRootParams = { assetId: string; type: 'video' | 'audio' };
export type UserRouteParams = { userId: string; accountId: string };

export const routes = {
  account: '/account/:accountId',
  accountAuthProvider: '/account/:accountId/auth-provider',
  accountCreate: '/account/create',
  accountFeatureFlags: '/account/:accountId/feature-flags',
  accountFlags: '/account/:accountId/flags',
  accountInfo: '/account/:accountId',
  accounts: '/accounts',
  accountTemplates: '/account/:accountId/templates',
  accountUsers: '/account/:accountId/users',
  export: '/exports/:id',
  exportPreview: '/exports/:id/preview',
  exportRenderDescription: '/exports/:id/render-description',
  exports: '/exports',
  featureFlag: '/feature-flags/:name',
  featureFlags: '/feature-flags',
  home: '/',
  project: '/project/:projectId',
  inspectIndex: '/inspect',
  inspectVideoAsset: '/inspect/video/:id',
  inspectVideoAssetOriginal: '/inspect/video/:id/original',
  music: '/music/:musicId',
  musicCreate: '/music/create',
  musics: '/music',
  musicUsers: '/music/:musicId/users',
  projectTemplates: '/project-templates',
  projectTemplate: '/project-templates/:projectTemplateId',
  userRoles: '/roles/users',
  staffUserRoles: '/roles/staff-users',
  signInError: '/signin-error',
  signout: '/signout',
  staffUser: '/staff-user/:staffUserId',
  staffUserCreate: '/staff-user/create',
  staffUsers: '/staff-users',
  template: '/template/:renderTemplateId',
  templateCreate: '/template/create',
  templateDefinition: '/template/:renderTemplateId/definition',
  templateExport: '/template/:renderTemplateId/export',
  templateFonts: '/template/:renderTemplateId/fonts',
  templateImport: '/template/import',
  templateLatestProjects: '/template/:renderTemplateId/latest-projects',
  templates: '/templates',
  templateTextCompositions: '/template/:renderTemplateId/text-compositions',
  templateUsers: '/template/:renderTemplateId/users',
  transcriptionReviewAsset: '/transcription-review/:assetId/:type',
  transcriptionReviewIndex: '/transcription-review',
  user: '/user/:userId',
  userCreate: '/user/create/:accountId',
} as const;

export type Routes = typeof routes;
export type RouteName = keyof Routes;
