import type { FC } from 'react';

import {
  Card,
  GridContainer,
  GridItem,
  Headline,
  Spacing,
  SpinningIcon,
  TextLink,
  formatDateTime,
  formatDuration,
  styled,
} from '@cofenster/web-components';

import type { VideoAsset } from '../../../api/hooks/videoAsset/useVideoAsset';
import {
  Definition,
  DefinitionContent,
  DefinitionList,
  DefinitionTerm,
} from '../../../components/display/DefinitionList';
import { InspectAnyLoudnessData } from '../InspectAnyLoudnessData';
import { InspectAssetPreview } from '../InspectAssetPreview';
import { InspectPlayground } from '../InspectPlayground';

const NoWrapDefinitionContent = styled(DefinitionContent)(() => ({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));

const StyledTextLink = styled(TextLink)(({ theme }) => ({
  color: theme.palette.brand.blue,
  borderRadius: 4,

  '&:focus-visible': theme.mixins.focusRing,
}));

const DefinitionURL: FC<{ label: string; url?: string | null }> = ({ label, url }) => {
  return url ? (
    <Definition>
      <DefinitionTerm>{label}</DefinitionTerm>
      <NoWrapDefinitionContent>
        <StyledTextLink href={url} target="_blank" variant="l">
          {url}
        </StyledTextLink>
      </NoWrapDefinitionContent>
    </Definition>
  ) : null;
};

const TextWithIcon = styled('span')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.5),
  alignItems: 'center',
}));

export const InspectVideoAssetContent: FC<{ videoAsset: NonNullable<VideoAsset> }> = ({ videoAsset }) => (
  <>
    <Spacing bottom={3}>
      <GridContainer>
        <GridItem xs={12} md={6}>
          <Card>
            <Spacing bottom={2}>
              <Headline component="h2" variant="h4">
                Metadata
              </Headline>
            </Spacing>

            <DefinitionList>
              <Definition>
                <DefinitionTerm>Video asset ID</DefinitionTerm>
                <DefinitionContent>{videoAsset.id}</DefinitionContent>
              </Definition>

              <Definition>
                <DefinitionTerm>Status</DefinitionTerm>
                <DefinitionContent>
                  <TextWithIcon>
                    {videoAsset.status === 'Waiting' && <SpinningIcon type="LoadingIcon" size="s" speed="fast" />}
                    {videoAsset.status}
                  </TextWithIcon>
                </DefinitionContent>
              </Definition>

              <Definition data-half>
                <DefinitionTerm>Created at</DefinitionTerm>
                <DefinitionContent>{formatDateTime(videoAsset.createdAt)}</DefinitionContent>
              </Definition>

              <Definition data-half>
                <DefinitionTerm>Updated at</DefinitionTerm>
                <DefinitionContent>
                  {videoAsset.updatedAt ? formatDateTime(videoAsset.updatedAt) : 'n/a'}
                </DefinitionContent>
              </Definition>

              <Definition data-half>
                <DefinitionTerm>Duration</DefinitionTerm>
                <DefinitionContent>{formatDuration(videoAsset.duration ?? 0)}</DefinitionContent>
              </Definition>

              <Definition data-half>
                <DefinitionTerm>Dimensions</DefinitionTerm>
                <DefinitionContent>
                  {typeof videoAsset.width === 'number' ? `${videoAsset.width}px × ${videoAsset.height}px` : 'Unknown'}
                </DefinitionContent>
              </Definition>
            </DefinitionList>
          </Card>
        </GridItem>
        <GridItem xs={12} md={6}>
          <Card>
            <Spacing bottom={2}>
              <Headline component="h2" variant="h4">
                URLs
              </Headline>
            </Spacing>

            <DefinitionList>
              <DefinitionURL label="Video URL" url={videoAsset.videoUrl} />
              <DefinitionURL label="Audio URL" url={videoAsset.audioUrl} />
              <DefinitionURL label="Thumbnail URL" url={videoAsset.thumbnailUrl} />
              <DefinitionURL label="Download URL" url={videoAsset.downloadUrl} />
              <DefinitionURL label="S3 URL" url={videoAsset.s3Url} />
            </DefinitionList>
          </Card>
        </GridItem>
      </GridContainer>
    </Spacing>

    {videoAsset && <InspectPlayground videoAsset={videoAsset} />}

    {videoAsset.videoUrl && (
      <InspectAssetPreview src={videoAsset.videoUrl} audioLoudnessUrl={videoAsset.audioLoudnessUrl} />
    )}

    <Spacing top={3}>
      <InspectAnyLoudnessData />
    </Spacing>
  </>
);
