const windowMapper = <T, R = T>(
  windowSize: number,
  mapper: (value: T, index: number, window: T[]) => R
): ((value: T, index: number, array: T[]) => R) => {
  return (value, index, array) => {
    const start = Math.max(0, index - Math.floor(windowSize / 2));
    const end = Math.min(array.length, index + Math.floor(windowSize / 2) + 1);
    const window = array.slice(start, end);
    return mapper(value, index, window);
  };
};

const movingAverage = (windowSize: number) =>
  windowMapper<number>(windowSize, (_value, _index, window) => {
    return window.reduce((sum, value) => sum + value, 0) / window.length;
  });

/**
 * Calculate the ducking factor based on the loudness data in dBFS.
 * Returned factors are between 1 if the audio is above threshold, 0 if it's below.
 */
export const loudnessToDuckingFactor = (loudness: (number | null)[]) => {
  // null values represent parts where there's no audio data
  const rawMin = Math.max(-100, Math.min(...loudness.filter((v) => v !== null)));
  const withoutNulls = loudness.map((v) => v ?? rawMin);
  const linear = withoutNulls.map((dBFS: number) => 10 ** (dBFS / 20));
  // denoise the signal by applying a moving average, to better detect speaking parts
  const denoised = linear.map(movingAverage(5));

  const denoisedMax = Math.max(...denoised);
  const denoisedMin = Math.min(...denoised);
  const linearThreshold = denoisedMin + 0.15 * (denoisedMax - denoisedMin);

  __DEBUG_AUDIO_DUCKING__ && console.log('linearThreshold', linearThreshold);

  const factors = denoised
    .map(
      // increasing window size would cause curve to start peaking earlier and end later
      windowMapper(30, (_value, _index, window) => {
        const aboveThreshold = window.filter((v) => v > linearThreshold);
        const speakingPercentage = aboveThreshold.length / window.length;
        return speakingPercentage > 0 ? 1 : 0;
      })
    )
    .map(movingAverage(15));

  __DEBUG_AUDIO_DUCKING__ && console.log('loudnessToDuckingFactor', { linear, denoised, factors });

  return { linear, denoised, factors };
};
