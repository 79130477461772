import { loudnessToDuckingFactor } from '../../../helpers/loudnessToDuckingFactor';
import type { DuckingSource } from './getAudioDuckingSources';

const LOUDNESS_DATA_CACHE = new Map<string, (number | null)[]>();

export const mapFrameToDuckingFactor = async (totalFrames: number, sources: DuckingSource[]) => {
  const loudnessByFrame: number[] = new Array(totalFrames).fill(Number.NEGATIVE_INFINITY);

  await Promise.all(
    Array.from(new Set(sources.map((source) => source.loudnessUrl))).map(async (loudnessUrl) => {
      if (LOUDNESS_DATA_CACHE.has(loudnessUrl)) return;
      const loudnessData = await fetch(loudnessUrl).then((res) => res.json() as Promise<(number | null)[]>);
      LOUDNESS_DATA_CACHE.set(loudnessUrl, loudnessData);
    })
  );

  for (const source of sources) {
    const sourceLoudness = LOUDNESS_DATA_CACHE.get(source.loudnessUrl);
    if (!sourceLoudness) throw new Error('Source loudness not found in cache');

    const startFrame = source.start;
    // The values prefixed with `asset…` contain values based on the asset timeline.
    const assetOffset = source.assetOffset ?? 0;
    const assetDuration = Math.round(source.duration * source.playbackRate) + assetOffset;

    for (let assetFrame = assetOffset; assetFrame < assetDuration; assetFrame++) {
      if (assetFrame >= sourceLoudness.length) console.error('Index out of source loudness bounds');
      const assetLoudness = sourceLoudness[assetFrame] ?? Number.NEGATIVE_INFINITY;

      const relativeDuration = assetDuration - assetOffset;
      const relativeFrame = assetFrame - assetOffset;
      const percent = relativeFrame / (relativeDuration - 1);

      const targetFrame = startFrame + Math.round((source.duration - 1) * percent);

      loudnessByFrame[targetFrame] = Math.max(loudnessByFrame[targetFrame] ?? 0, assetLoudness ?? 0);
    }
  }

  return loudnessToDuckingFactor(loudnessByFrame);
};
