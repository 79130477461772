import { AWS_AUDIO_KEY_NOT_APPLICABLE, LAYER_INDEXES } from '@cofenster/constants';
import {
  introOutroDuration,
  isSceneAudio,
  isSceneVideo,
  sceneDuration,
  sceneVideoDuration,
  sceneVideoSectionDuration,
  secondsToFrames,
} from '@cofenster/render-description';
import type { RenderDescription } from '@cofenster/render-worker';

export type DuckingSource = {
  type: 'audio' | 'video';
  // in frames
  start: number;
  // in frames
  duration: number;
  playbackRate: number;
  loudnessUrl: string;
  // in frames
  assetOffset?: number;
};

export const getAudioDuckingSources = (rd: RenderDescription): DuckingSource[] => {
  const fps = rd.format.fps ?? 30;

  const mediaAssets: DuckingSource[] = [];

  let offset = 0;
  if (rd.intro?.duration) {
    offset += introOutroDuration(rd.intro, fps);
  }

  for (const scene of rd.scenes) {
    const duration = sceneDuration(scene, fps);

    for (const asset of scene.sceneAssets) {
      if (isSceneVideo(asset)) {
        const sceneVideo = asset;
        if (sceneVideo.volume === 0) continue;
        if (!sceneVideo.audioLoudnessUrl) continue;
        if (sceneVideo.audioLoudnessUrl === AWS_AUDIO_KEY_NOT_APPLICABLE) continue;

        if (sceneVideo.sections?.length) {
          let sectionOffset = 0;
          for (const section of sceneVideo.sections) {
            const sectionDuration = sceneVideoSectionDuration(section, sceneVideo.playbackRate, fps);
            mediaAssets.push({
              type: 'video',
              start: offset + sectionOffset,
              duration: sectionDuration,
              playbackRate: sceneVideo.playbackRate,
              loudnessUrl: sceneVideo.audioLoudnessUrl,
              assetOffset: secondsToFrames(section.offsetIn, fps),
            });
            sectionOffset += sectionDuration;
          }

          if (sceneVideo.layerIndex === LAYER_INDEXES.main) {
            console.assert(sectionOffset === duration, 'Total duration mismatch');
          }
        } else {
          mediaAssets.push({
            type: 'video',
            start: offset + secondsToFrames(sceneVideo.startTime ?? 0, fps),
            duration: sceneVideoDuration(sceneVideo, fps),
            playbackRate: sceneVideo.playbackRate,
            loudnessUrl: sceneVideo.audioLoudnessUrl,
          });
        }
      }

      if (isSceneAudio(asset)) {
        const sceneAudio = asset;
        if (sceneAudio.volume === 0) continue;
        if (!sceneAudio.audioLoudnessUrl) continue;
        if (sceneAudio.audioLoudnessUrl === AWS_AUDIO_KEY_NOT_APPLICABLE) continue;
        mediaAssets.push({
          type: 'audio',
          start: offset + secondsToFrames(sceneAudio.startTime ?? 0, fps),
          // do not use sceneAssetDuration here, it returns 0 for audio
          duration: secondsToFrames(asset.duration, fps),
          playbackRate: 1,
          loudnessUrl: sceneAudio.audioLoudnessUrl,
        });
      }
    }

    offset += duration;
  }

  if (rd.outro?.duration) {
    offset += introOutroDuration(rd.outro, fps);
  }

  console.assert(offset === rd.totalDurationInFrames, 'Total duration mismatch');

  __DEBUG_AUDIO_DUCKING__ && console.log('getAudioDuckingSources', mediaAssets);

  return mediaAssets;
};
