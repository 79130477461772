import type { SubtitleNode as RenderDescriptionSubtitleNode, SceneVideoSection } from '../types/RenderDescription';

import { assetWithSectionsTiming } from './assetWithSectionsTiming';

type VideoSection = Omit<SceneVideoSection, '__typename'>;
type SubtitleNode = Omit<RenderDescriptionSubtitleNode, '__typename'>;

export const subtitleNodesBySections = (
  subtitleNodes: SubtitleNode[],
  videoSections: VideoSection[],
  duration: number,
  playbackRate: number
) => {
  if (!videoSections.length)
    return subtitleNodes.filter(isNotEmpty).map(({ start, end, ...rest }) => ({
      start: start / playbackRate,
      end: end / playbackRate,
      ...rest,
    }));

  const timing = assetWithSectionsTiming(duration, playbackRate, videoSections);

  return Array.from(videoSections)
    .sort((a, b) => a.offsetIn - b.offsetIn)
    .flatMap((videoSection) => {
      return subtitleNodes
        .filter((subtitleNode) => isSubtitleNodeOverlappingVideoSection(videoSection, subtitleNode))
        .filter(isNotEmpty)
        .map(({ start, end, ...rest }) => ({
          start: timing.fromTimeline(Math.max(start, videoSection.offsetIn) / playbackRate),
          end: timing.fromTimeline(Math.min(end, videoSection.offsetOut) / playbackRate),
          ...rest,
        }));
    });
};

const isNotEmpty = (subtitleNode: SubtitleNode) => subtitleNode.text.trim() !== '';

export const isSubtitleNodeOverlappingVideoSection = (videoSection: VideoSection, subtitleNode: SubtitleNode) =>
  subtitleNode.start < videoSection.offsetOut && subtitleNode.end > videoSection.offsetIn;

export const isSubtitleNodeWithinVideoSection = (videoSection: VideoSection, subtitleNode: SubtitleNode) =>
  subtitleNode.start >= videoSection.offsetIn && subtitleNode.end <= videoSection.offsetOut;
