import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { MainNavigation, Sidebar } from '@cofenster/web-components';

import { useStaffUser } from '../../contexts/staffUser/useStaffUser';
import { routes } from '../../routes';

import { MainNavigationRouterLink } from './MainNavigationRouterLink';
import { ProfileButton } from './ProfileButton';

export const AdminNavigation: FC = () => {
  const { staffUser } = useStaffUser();
  const { id } = useParams();

  return (
    <Sidebar admin>
      <MainNavigation
        data-testid="sidebar-admin"
        renderBottom={() => (
          <>
            <MainNavigation.Divider />
            {staffUser && <ProfileButton user={staffUser} />}
          </>
        )}
      >
        <MainNavigation.SkipNavigationLink href="#content" data-testid="skip-link">
          Skip navigation
        </MainNavigation.SkipNavigationLink>

        <MainNavigation.NavSection>
          <MainNavigation.SectionHeader>Managing</MainNavigation.SectionHeader>
          <MainNavigationRouterLink
            to="accounts"
            startIcon="AccountIcon"
            matchPaths={[`${routes.account}`, `${routes.account}/*`, `${routes.accounts}/*`]}
          >
            Accounts
          </MainNavigationRouterLink>
          <MainNavigationRouterLink
            to="featureFlags"
            matchPaths={[`${routes.featureFlags}/*`, `${routes.featureFlag}/*`]}
            startIcon="FlagIcon"
          >
            Feature Flags
          </MainNavigationRouterLink>
          <MainNavigationRouterLink
            to="musics"
            matchPaths={[`${routes.music}/*`, `${routes.musics}/*`]}
            startIcon="MusicNoteIcon"
          >
            Music
          </MainNavigationRouterLink>
          <MainNavigationRouterLink to="projectTemplates" matchPaths={[routes.projectTemplates]} startIcon="LayoutIcon">
            Project templates
          </MainNavigationRouterLink>
          <MainNavigationRouterLink
            to="templates"
            matchPaths={[`${routes.template}/*`, `${routes.templates}/*`]}
            startIcon="FourSquaresIcon"
          >
            Templates
          </MainNavigationRouterLink>
        </MainNavigation.NavSection>

        <MainNavigation.NavSection>
          <MainNavigation.SectionHeader>Info</MainNavigation.SectionHeader>

          <MainNavigationRouterLink to="userRoles" matchPaths={[routes.userRoles]} startIcon="UserIcon">
            Roles
          </MainNavigationRouterLink>
        </MainNavigation.NavSection>

        <MainNavigation.NavSection>
          <MainNavigation.SectionHeader>Debugging</MainNavigation.SectionHeader>
          <MainNavigationRouterLink to="exports" matchPaths={[`${routes.exports}/*`]} startIcon="VideoIcon">
            Exports
          </MainNavigationRouterLink>

          <MainNavigationRouterLink
            to="inspectIndex"
            params={{ id }}
            matchPaths={[`${routes.inspectIndex}`, `${routes.inspectVideoAsset}/*`]}
            startIcon="ImageIcon"
          >
            Inspect asset
          </MainNavigationRouterLink>

          <MainNavigationRouterLink
            to="transcriptionReviewIndex"
            matchPaths={[`${routes.transcriptionReviewIndex}`, `${routes.transcriptionReviewAsset}`]}
            startIcon="TextIcon"
          >
            Transcription
          </MainNavigationRouterLink>
        </MainNavigation.NavSection>
      </MainNavigation>
    </Sidebar>
  );
};
