import type { SceneVideo } from '../types/RenderDescription';

import { sceneVideoSectionDuration } from './sceneVideoSectionDuration';
import { secondsToFrames } from './secondsToFrames';

export const sceneVideoDuration = (sceneVideo: SceneVideo, fps: number, ignoreSections = false) => {
  if (!sceneVideo.sections?.length || ignoreSections) {
    return secondsToFrames(sceneVideo.duration / sceneVideo.playbackRate, fps);
  }
  return sceneVideo.sections.reduce((acc, section) => {
    return acc + sceneVideoSectionDuration(section, sceneVideo.playbackRate, fps);
  }, 0);
};
