import { loudnessToDuckingFactor } from '@cofenster/render-template-engine';
import { Card, Headline, Spacing, TextField } from '@cofenster/web-components';
import { type FC, useState } from 'react';
import { AudioDuckingChart, type useAudioDuckingData } from './InspectAssetPreview';

export const InspectAnyLoudnessData: FC = () => {
  const [pastedData, setPastedData] = useState<{
    rawData: (number | null)[];
    data: ReturnType<typeof useAudioDuckingData>['data'];
  } | null>(null);

  return (
    <Card>
      <Spacing bottom={2}>
        <Headline component="h2" variant="h4">
          Loudness data chart from input
        </Headline>
      </Spacing>
      <Spacing top={2}>
        <TextField
          label="Visualise loudness data"
          onChange={(event) => {
            try {
              const rawData = JSON.parse(event.target.value);
              setPastedData({
                rawData,
                data: loudnessToDuckingFactor(rawData),
              });
            } catch (ex) {
              console.log(ex);
            }
          }}
          fullWidth
        />
        {pastedData && (
          <AudioDuckingChart data={pastedData.data} rawData={pastedData.rawData} playbackProgress={undefined} />
        )}
      </Spacing>
    </Card>
  );
};
